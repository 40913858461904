export const categories = [
  {
    id: 1,
    name: "NikhilRuchikaPreWeddinginMumbai",
    displayName: "Ruchika & Nikhil",
    thumbnail: "assets/img/gallery/NikhilRuchikaPreWeddinginMumbai/thumb/1.jpg",
    count: 22,
  },
  {
    id: 2,
    name: "ShalakaPrasadWeddinginPune",
    displayName: "Shalaka & Prasad",
    thumbnail: "assets/img/gallery/ShalakaPrasadWeddinginPune/thumb/1.jpg",
    count: 28,
  },
  {
    id: 3,
    name: "NikhilTiteekshaWeddingKarad",
    displayName: "Nikhil & Titeeksha",
    thumbnail: "assets/img/gallery/NikhilTiteekshaWeddingKarad/thumb/1.jpg",
    count: 37,
  },
  {
    id: 4,
    name: "HarshaKarthikWeddingMumbai",
    displayName: "Harsha & Karthik",
    thumbnail: "assets/img/gallery/HarshaKarthikWeddingMumbai/thumb/1.jpg",
    count: 36,
  },
  {
    id: 5,
    name: "HiteshShwetaMaternityPune",
    displayName: "Hitesh & Shweta",
    thumbnail: "assets/img/gallery/HiteshShwetaMaternityPune/thumb/1.jpg",
    count: 25,
  },
  {
    id: 6,
    name: "BestOnes",
    displayName: "Portfolio",
    thumbnail:
      "assets/img/gallery/BestOnes/thumb/1.jpg",
    count: 49,
  },
];
