import React from "react";
import Skeleton from "react-loading-skeleton";

const CarouselSkeleton = () => {
  const height = window.innerWidth < 1000 ? 400 : 800; //Define height of skeleton for variable heights of window
  return (
    <React.Fragment>
      <div
        className="padding_top"
        style={{
          maxWidth: "950px",
          marginLeft: "auto",
          marginRight: "auto",
          paddingTop: "125px",
        }}
      >
        <Skeleton height={height} />
      </div>
    </React.Fragment>
  );
};

export default CarouselSkeleton;
