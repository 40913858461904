import React from "react";
import ReactPlayer from "react-player";
import { withRouter } from "react-router";
import { Spring } from "react-spring/renderprops";
import VisibilitySensor from "react-visibility-sensor";
import PropTypes from "prop-types";

function FilmPlayer(props) {
  const { location } = props;
  // Determine the css class based on current page location
  const currentLocation =
    location.pathname === "/home" || location.pathname === "/" ? true : false;

  // Begin polling for the required lazy images tag and observe the images for intersection
  React.useEffect(() => {
    if (currentLocation) {
      const reactPlayerImagePoll = setInterval(() => {
        if (document.querySelectorAll(".react-player__preview").length > 0) {
          clearInterval(reactPlayerImagePoll);
          const observer = new IntersectionObserver((entry, imgObserver) => {
            entry.forEach((element) => {
              if (element.isIntersecting) {
                const lazyImage = element.target;
                lazyImage.style.backgroundImage =
                  // eslint-disable-next-line react/prop-types
                  `url("${props.data.lightImg}")`;
                imgObserver.unobserve(lazyImage);
              }
            });
          });
          const elementArray = document.querySelectorAll(
            ".react-player__preview"
          );
          elementArray.forEach((element) => {
            observer.observe(element);
          });
        }
      }, 200);
    }
  }, [currentLocation, props.data.lightImg]);

  return (
    <VisibilitySensor partialVisibility>
      {({ isVisible }) => (
        <Spring
          config={{ duration: 900 }}
          to={{
            opacity: isVisible ? 1 : 0.2,
          }}
        >
          {(item) => (
            <div
              style={{ ...item, margin: "20px 0px", padding: "0px" }}
              className="col-xl-12 col-md-12"
            >
              <div style={{ margin: "0 auto", position: "relative" }}>
                <div
                  className={currentLocation ? "film-container" : "film"}
                >
                  <ReactPlayer
                    // eslint-disable-next-line react/prop-types
                    url={`"${props.data.url}"`}
                    volume={1}
                    width="100%"
                    className="film-player"
                    height="34.9375rem"
                    controls={true}
                    controlsList="nodownload"
                    // eslint-disable-next-line react/prop-types
                    light={
                      currentLocation
                        ? // eslint-disable-next-line react/prop-types
                          `"${props.data.lightImgLazy}"`
                        : // eslint-disable-next-line react/prop-types
                          `"${props.data.lightImg}"`
                    }
                  />
                </div>
              </div>
            </div>
          )}
        </Spring>
      )}
    </VisibilitySensor>
  );
}

FilmPlayer.propTypes = {
  location: PropTypes.object.isRequired,
  data:{
    lightImg: PropTypes.object.isRequired
  }
};

export default withRouter(FilmPlayer);
