import React, { Component } from "react";
import PropTypes from "prop-types";
import Gallery from "react-photo-gallery";
import "./../Shared/styles/gallery.css";
import GallerySkeleton from "../Shared/GallerySkeleton";

//Lazyload the LightBox component on demand.
const MyLazyLightBox = React.lazy(() => import("./LighBoxModule"));

export default class MyGallery extends Component {
  constructor(props) {
    super(props);

    this.state = {
      category: this.props.location.state.currentCategory,
      imageCount: this.props.location.state.imageCount,
      images: [],
      imagesForLightBox: [],
      isOpen: false,
      photoIndex: 0,
      observerEnabledGallery: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0); //Always scroll to top of the page on mount

    //Logic to load images as per dimensions for the masonary gallery.
    [...Array(this.state.imageCount)].forEach((_, index) => {
      let img = new Image();
      img.src = `assets/img/gallery/${this.state.category}/lazy_image/${
        index + 1
      }.jpg`;
      img.onload = () => {
        let imgObj = {
          src: img.src,
          width: img.width,
          height: img.height,
          id: index + 1,
          className: "lazy",
          alt: `assets/img/gallery/${this.state.category}/${index + 1}.jpg`,
        };

        this.setState((prevState) => ({
          images: prevState.images.concat([imgObj]),
        }));
      };
    });

    //Logic to load the images for the lightBox component
    const lighBoxImages = [...Array(this.state.imageCount)].map((_, index) => {
      let imgObj = {
        src: `assets/img/gallery/${this.state.category}/${index + 1}.jpg`,
        id: index + 1,
      };
      return imgObj;
    });
    this.setState({ imagesForLightBox: lighBoxImages });
  }

  componentDidUpdate() {
    // Poll for lazy images and begin observation on load.
    const imgTagPoll = setInterval(() => {
      if (
        document.querySelectorAll("img.lazy").length ===
          this.state.imageCount &&
        !this.state.observerEnabledGallery
      ) {
        clearInterval(imgTagPoll);
        this.imageObserver = new IntersectionObserver((entry, imgObserver) => {
          entry.forEach((element) => {
            if (element.isIntersecting) {
              const lazyImage = element.target;
              const originalImage = lazyImage.src.replace("lazy_image/", "");
              lazyImage.src = originalImage;
              lazyImage.classList.remove("lazy");
              imgObserver.unobserve(lazyImage);
            }
          });
        });
        const arr = document.querySelectorAll("img.lazy");
        arr.forEach((v) => {
          this.imageObserver.observe(v);
        });

        this.setState({ observerEnabledGallery: true });
      }
    }, 200);
  }

  // Callback fired after lightBox is closed
  onCloseRequest = () => {
    this.setState({ isOpen: false });
  };

  // Callback fired after the previous lightbox image is requested
  onMovePrevRequest = () => {
    this.setState({
      photoIndex:
        (this.state.photoIndex + this.state.imagesForLightBox.length - 1) %
        this.state.imagesForLightBox.length,
    });
  };

  // Callback fired after the next lightbox image is requested
  onMoveNextRequest = () => {
    this.setState({
      photoIndex:
        (this.state.photoIndex + 1) % this.state.imagesForLightBox.length,
    });
  };

  // Callback fired after lightBox is requested to open
  handleOpenLightbox = (e, { index }) => {
    this.setState({ isOpen: true, photoIndex: index });
  };

  // Specify the number of columns in the image gallery.
  columns(containerWidth) {
    let columns = 1;
    if (containerWidth >= 500) columns = 2;
    if (containerWidth >= 900) columns = 2;
    if (containerWidth >= 1500) columns = 2;
    return columns;
  }

  render() {
    const { images, imagesForLightBox, photoIndex, isOpen } = this.state;
    if (images.length > 0 && imagesForLightBox.length > 0) {
      return (
        <React.Fragment>
          {/* Display cover for gallery*/}
          <div className="padding_top">
            <img
              style={{ width: "100%" }}
              alt="cover"
              src={`assets/img/gallery/${this.state.category}/cover/1.jpg`}
            />
          </div>

          {/* Gallery Area */}
          <div style={{ padding: "6% 8% 8%" }}>
            <Gallery
              direction={"column"}
              photos={images.sort((a, b) => a.id - b.id)}
              onClick={this.handleOpenLightbox}
              columns={this.columns}
            />
          </div>
          {/* Gallery Area */}

          {/* Lightbox for the gallery */}
          {isOpen && (
            <React.Suspense fallback={<div>Loading...</div>}>
              <MyLazyLightBox
                imagesForLightBox={imagesForLightBox}
                photoIndex={photoIndex}
                onCloseRequest={this.onCloseRequest}
                onMoveNextRequest={this.onMoveNextRequest}
                onMovePrevRequest={this.onMovePrevRequest}
              />
            </React.Suspense>
          )}
          {/* Lightbox for the gallery */}
        </React.Fragment>
      );
    } else {
      return <GallerySkeleton />;
    }
  }
}

MyGallery.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      currentCategory: PropTypes.string.isRequired,
      imageCount: PropTypes.number.isRequired,
    }),
  }),
};
