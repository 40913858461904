import React, { Component } from "react";
import Carousel from "../Shared/Carousel";
import Category from "./Category";
import { categories } from "../../AppData/category";

export default class Album extends Component {
  constructor(props) {
    super(props);

    this.state = {
      categories: categories,
      totalImages: 20,
      carousel: [],
    };
  }

  componentDidMount() {
    // Get the images to be displayed in carousel
    // Images are present in numbered format e.g 1.jpg, 2.jpg
    let carouselImages = [];
    carouselImages = [...Array(this.state.totalImages)].map((_, index) => {
      return {
        id: index + 1,
        image: `assets/img/gallery/${index + 1}.jpg`,
        thumb: `assets/img/gallery/thumb/${index + 1}.jpg`,
      };
    });
    this.setState({ carousel: carouselImages });
  }

  render() {
    return (
      <React.Fragment>
        <div>
          {/* Carousel Component */}
          {this.state.carousel.length > 0 && (
            <Carousel carouselImages={this.state.carousel} />
          )}

          {/* Title text for Albums */}
          <h3
            style={{
              textAlign: "center",
              marginTop: "50px",
              fontSize: "28px",
              textTransform: "uppercase",
            }}
          >
            Featured Albums
          </h3>

          {/* Album Area */}
          <div
            style={{ paddingTop: "50px", paddingBottom: "100px" }}
            className="gallery_area"
          >
            <div className="container-fluid p-0">
              <div
                style={{ marginLeft: "15%", marginRight: "15%" }}
                className="row no-gutters "
              >
                {this.state.categories.map((category) => (
                  <Category
                    key={category.id}
                    title={category.name}
                    displayName={category.displayName}
                    thumbnail={category.thumbnail}
                    imageCount={category.count}
                  />
                ))}
              </div>
            </div>
          </div>
          {/* Album Area */}
        </div>
      </React.Fragment>
    );
  }
}
