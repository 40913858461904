import React, { Component } from "react";
import styled from "styled-components";
import { Spring } from "react-spring/renderprops";
import VisibilitySensor from "react-visibility-sensor";

// Component Styles

const H2TitleStyle = {
  fontSize: "1.6875rem",
  letterSpacing: "0.3em",
  wordSpacing: "0em",
  lineHeight: "1.34em",
  textTransform: "uppercase",
  fontWeight: "normal",
};

const ParagraphFontStyle = {
  fontFamily: "Josefin Sans, sans-serif",
  fontSize: "0.875rem",
  wordSpacing: "0em",
  lineHeight: "1.625em",
  textTransform: "none",
  fontWeight: "normal",
};

const FAQQuestionStyle = {
  fontFamily: "Josefin Sans",
  fontSize: "16px",
  wordSpacing: "0em",
  lineHeight: "1.49em",
  textTransform: "none",
  fontWeight: "600",
  color: "black",
};

const FAQAnswerStyle = {
  fontFamily: "Josefin Sans",
  fontSize: "15px",
  wordSpacing: "0em",
  lineHeight: "1.625em",
  textTransform: "none",
  fontWeight: "normal",
  color: "black",
};

//Styled Component

const LineStyleDiv = styled.div`
  @media (min-width: 48em) {
    width: 1px;
    height: 80%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.14);
  }
`;

class About extends Component {
  render() {
    return (
      <React.Fragment>
        <Spring
          from={{ opacity: 0 }}
          to={{ opacity: 1 }}
          config={{ duration: 600 }}
          delay={600}
        >
          {(props) => (
            <div className="padding_top">
              <img
                style={{ ...props, width: "100%" }}
                alt="cover"
                src={`assets/img/gallery/cover/AboutUs.jpg`}
              />
            </div>
          )}
        </Spring>

        <div className="contact-section">
          <div className="container">
            <div className="row">
              <div
                style={{ paddingBottom: "3%", textAlign: "center" }}
                className="col-lg-12 about_header"
              >
                <h2 style={H2TitleStyle} className="text-center">
                  WHO ARE WE?
                </h2>

                <p>
                  Hi there, welcome to Omkar Kalgude Photography & Films. We are
                  an au fait squad of Candid Photographers and Cinematographers
                  who love capturing your stories as much you love each other.
                  Our zealous team is based in India, a land where weddings are
                  a larger than life affair. Led by Omkar Kalgude, we started
                  photographing weddings in 2018, and since then we’ve been on a
                  roll capturing hundreds of love stories across India and
                  overseas. It’ll be our crowning glory to be a part of your big
                  day that embellishes a beautiful journey. We can’t wait to
                  hear from you!
                </p>
              </div>
              <div
                style={{ paddingBottom: "5%", textAlign: "center" }}
                className="col-lg-12 about_header"
              >
                <h2 style={H2TitleStyle} className="text-center">
                  OUR PHILOSOPHY
                </h2>

                <p>
                  We believe that it’s the little moments that make a wedding.
                  They weave together into a memorable tale that you can cherish
                  for a lifetime. With our cameras at work, we witness your love
                  stories as they unfold and document them through a creative
                  lens. It’s your wedding day and you deserve to indulge in an
                  unobtrusive experience and bid adieu to awkward poses.
                </p>
              </div>
              <div
                className="col-lg-6 col-sm-12 about_img"
                style={{
                  marginBottom: "1.25rem",
                  width: "24.125rem",
                }}
              >
                <img
                  style={{
                    width: "100%",
                    height: "100%",
                    // objectFit: "cover",
                    objectPosition: "center",
                  }}
                  alt="about"
                  src="assets\img\about\omkar_kalgude.jpg"
                />
              </div>
              <div className="col-lg-6" style={{ textAlign: "justify" }}>
                <div className="about_text" style={ParagraphFontStyle}>
                  <h3 className="about_name">OMKAR KALGUDE</h3>
                  <p>
                    Being a Software Engineer by profession, Omkar Kalgude has
                    worked in the IT industry for 5 years before switching
                    full-time to his photography career.
                  </p>
                  <p>
                    It all started with a passion to click photographs and a
                    desire to tell stories from a unique perspective. He always
                    had the knack for capturing emotions, stories, and colors
                    around him. He started with wedding photography and captured
                    the bittersweet moments by adding a personal touch to the
                    craft.
                  </p>
                  <p>
                    Together, Omkar and his team have worked with over 100
                    couples and delivered heartwarming stories not only of the
                    couples but also of their families. They don't just shoot
                    what it looks like but also what it feels like. They
                    continue to capture the magic to gift you moments that stay
                    with you throughout your life.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12 pb-4 pb-sm-3">
          <h2 style={{ ...H2TitleStyle }} className="text-center">
            We turn your dream wedding into a fairy tale.
          </h2>
          <h2
            style={{ marginBottom: "5%", ...H2TitleStyle }}
            className="text-center"
          >
            Talk to us today!
          </h2>
        </div>

        {/* Fade effect when partially visible */}
        <VisibilitySensor partialVisibility>
          {({ isVisible }) => (
            <Spring
              config={{ duration: 1000 }}
              to={{
                opacity: isVisible ? 1 : 0.2,
              }}
            >
              {(props) => (
                <div>
                  <div
                    className="container"
                    style={{ ...props, marginBottom: "80px" }}
                  >
                    <div className="row" style={{ position: "relative" }}>
                      <LineStyleDiv
                        className="middle_line"
                        style={{ marginTop: "35px" }}
                      ></LineStyleDiv>
                      <div
                        style={{ paddingBottom: "80px" }}
                        className="col-lg-12"
                      >
                        <h2 style={H2TitleStyle} className="text-center">
                          FAQS
                        </h2>
                      </div>

                      <div
                        className="col-lg-6"
                        style={{
                          paddingLeft: "4.375rem",
                          paddingRight: "3.1875rem",
                          marginBottom: "1.75rem",
                        }}
                      >
                        <h3 style={FAQQuestionStyle}>
                          • Where are you guys based out of, do you travel to
                          different locations for weddings?
                        </h3>
                        <h4 style={FAQAnswerStyle}>
                          We are based in Maharashtra, India and we travel
                          across the globe for shoots.
                        </h4>
                      </div>
                      <div
                        className="col-lg-6"
                        style={{
                          paddingLeft: "4.375rem",
                          paddingRight: "3.1875rem",
                          marginBottom: "1.75rem",
                        }}
                      >
                        <h3 style={FAQQuestionStyle}>
                          • What are the various services you offer?
                        </h3>
                        <h4 style={FAQAnswerStyle}> Wedding Photography</h4>
                        <h4 style={FAQAnswerStyle}> Wedding Cinematography</h4>
                        <h4 style={FAQAnswerStyle}>
                          {" "}
                          Pre & Post Wedding Shoot
                        </h4>
                        <h4 style={FAQAnswerStyle}>
                          {" "}
                          Couple & Family Portraits
                        </h4>
                        <h4 style={FAQAnswerStyle}> Kids Portraits</h4>
                        <h4 style={FAQAnswerStyle}> Maternity Portraits</h4>
                      </div>
                      <div className="w-100"></div>
                      <div
                        className="col-lg-6"
                        style={{
                          paddingLeft: "4.375rem",
                          paddingRight: "3.1875rem",
                          marginBottom: "1.75rem",
                        }}
                      >
                        <h3 style={FAQQuestionStyle}>
                          • What is the difference between candid and
                          traditional Photography
                        </h3>
                        <h4 style={FAQAnswerStyle}>
                          Candid Photography is shooting the event as it
                          unfolds, it is shot very naturally without any posing
                          elements. Traditional photography is documenting the
                          entire day in a much more safer perspective. It
                          involves crowd coverage, posed stage pictures and
                          every generic element is covered.
                        </h4>
                      </div>
                      <div
                        className="col-lg-6"
                        style={{
                          paddingLeft: "4.375rem",
                          paddingRight: "3.1875rem",
                          marginBottom: "1.75rem",
                        }}
                      >
                        <h3 style={FAQQuestionStyle}>
                          • When will we receive our images?
                        </h3>
                        <h4 style={FAQAnswerStyle}>
                          You will get all your proofs typically within two
                          weeks. Immediately post wedding, or a few days after,
                          we post some preview photos of your love story on
                          Instagram. Your pictures will be colour-corrected,
                          edited, and enhanced for effect. The package includes
                          all these high-resolution pictures in a Pendrive or an
                          album as requested by the couple.
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Spring>
          )}
        </VisibilitySensor>
      </React.Fragment>
    );
  }
}

export default About;
