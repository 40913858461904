import React from "react";
import Skeleton from "react-loading-skeleton";

// Skeleton loader for home page carousel
const HomeCarouselSkeleton = () => {
  const height = window.innerWidth < 1000 ? 400 : 800;
  return (
    <React.Fragment>
      <div style={{ padding: "0.5%" }}>
        <Skeleton height={height} />
      </div>
    </React.Fragment>
  );
};

export default HomeCarouselSkeleton;
