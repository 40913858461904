import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import PropTypes from "prop-types";
import { Spring } from "react-spring/renderprops";
import "../Shared/styles/override.css";
import { Carousel } from "react-responsive-carousel";
import CarouselSkeleton from "./CarouselSkeleton";
export default class MyCarousel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentSlide: 0,
      firstLoad: true,
      isLoading: true,
    };
  }

  // Begin polling for the required lazy images tag and observe the images for intersection
  componentDidMount() {
    const lazyImagePoll = setInterval(() => {
      if (
        this.state.firstLoad &&
        document.querySelectorAll("img.lazy_load_image").length > 0
      ) {
        clearInterval(lazyImagePoll);
        this.imageObserver = new IntersectionObserver((entry, imgObserver) => {
          entry.forEach((element) => {
            if (element.isIntersecting) {
              const lazyImage = element.target;
              lazyImage.src = lazyImage.dataset.src;
              lazyImage.classList.remove("lazy_load_image");
              imgObserver.unobserve(lazyImage);
            }
          });
        });

        const arr = document.querySelectorAll("img.lazy_load_image");
        arr.forEach((v) => {
          this.imageObserver.observe(v);
        });
        this.setState({ firstLoad: false });
      }
    }, 200);
  }

  // Callback fired on next image request in carousel
  next = () => {
    this.setState((state) => ({
      currentSlide: state.currentSlide + 1,
    }));
  };

  // Callback fired on previous image request in carousel
  prev = () => {
    this.setState((state) => ({
      currentSlide: state.currentSlide - 1,
    }));
  };

  // Callback fired after the slide is changed in carousel
  updateCurrentSlide = (index) => {
    const { currentSlide } = this.state;

    if (currentSlide !== index) {
      this.setState({
        currentSlide: index,
      });
    }
  };

  // Callback fired when first image in carousel loads and carousel skeleton disappears
  stopLoader = () => {
    this.setState({ isLoading: false });
  };

  render() {
    const { carouselImages } = this.props;
    return (
      <Spring
        from={{ opacity: 0 }}
        to={{ opacity: 1 }}
        config={{ duration: 800 }}
        delay={800}
      >
        {(props) => (
          <div
            className="padding_top"
            style={{
              ...props,
              maxWidth: "950px",
              marginLeft: "auto",
              marginRight: "auto",
              paddingTop: "125px",
            }}
          >
            <div onClick={this.prev} className="my-prev-arrow">
              <i
                className="fa fa-angle-left"
                style={{ fontSize: "1.5em", color: "grey" }}
              />
            </div>
            <div onClick={this.next} className="my-next-arrow">
              <i
                className="fa fa-angle-right"
                style={{ fontSize: "1.5em", color: "grey" }}
              />
            </div>

            <Carousel
              style={{ cursor: "pointer" }}
              showIndicators={false}
              showStatus={false}
              showThumbs={true}
              thumbWidth={135}
              infiniteLoop
              stopOnHover={false}
              autoPlay={true}
              dynamicHeight
              showArrows={false}
              selectedItem={this.state.currentSlide}
              onChange={this.updateCurrentSlide}
            >
              {carouselImages.map((item) => {
                return (
                  <div key={item.id} style={{ height: "100%" }}>
                    <img
                      className="lazy_load_image"
                      style={{ height: "100%" }}
                      alt="Carousel"
                      onLoad={() => {
                        if (item.id === 1) this.stopLoader();
                      }}
                      data-src={item.image}
                      src={item.thumb}
                    />
                  </div>
                );
              })}
            </Carousel>

            {this.state.isLoading && <CarouselSkeleton />}
          </div>
        )}
      </Spring>
    );
  }
}

MyCarousel.propTypes = {
  carouselImages: PropTypes.array.isRequired,
};
