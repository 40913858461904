import React, { Component } from "react";
import { FormGroup, Input, Alert } from "reactstrap";
import axios from "axios";
import { Spring } from "react-spring/renderprops";
import VisibilitySensor from "react-visibility-sensor";
import * as constants from "../../config/Constants";

// Styles defined as css objects
const DivImgStyle = {
  width: "200px",
  margin: "auto",
  height: "110px",
  textAlign: "center",
};

const ImgStyle = {
  height: "80px",
  width: "75%",
};

const H3Style = {
  paddingTop: "10px",
};

class ContactUs extends Component {
  constructor() {
    super();

    this.state = {
      message: "",
      name: "",
      mobile: "",
      email: "",
      subject: "",
      showSuccessAlert: false,
      showErrorAlert: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  // Store the input values in state
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  // Callback for Form submissions
  handleSubmit = (e) => {
    e.preventDefault();
    const { message, name, email, mobile, subject } = this.state;
    const userData = {
      message,
      email,
      mobile,
      name,
      subject,
    };

    // Perform send mail task using the firebase function
    axios.post(`${constants.SEND_MAIL_URL}`, { userData }).then((res) => {
      console.log(res);
      if (res && (res === "Sended" || res.data === "Sended")) {
        this.setState({
          showSuccessAlert: true,
          message: "",
          name: "",
          mobile: "",
          email: "",
          subject: "",
        });
      } else {
        this.setState({
          showErrorAlert: true,
        });
      }
    });
  };

  render() {
    return (
      <React.Fragment>
        <Spring
          from={{ opacity: 0 }}
          to={{ opacity: 1 }}
          config={{ duration: 600 }}
          delay={600}
        >
          {(props) => (
            <div className="padding_top">
              <img
                style={{ ...props, width: "100%" }}
                alt="cover"
                src={`assets/img/gallery/cover/ContactUs.jpg`}
              />
            </div>
          )}
        </Spring>

        <div className="contact-section">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2 className="contact-title">GET IN TOUCH</h2>
              </div>
              <div className="col-lg-8">
                <form
                  onSubmit={this.handleSubmit}
                  className="form-contact contact_form"
                >
                  <div className="row">
                    <div className="col-12">
                      <FormGroup>
                        <Input
                          type="text"
                          name="name"
                          placeholder="Name"
                          required
                          value={this.state.name}
                          onChange={this.handleChange}
                        />
                      </FormGroup>
                    </div>
                    <div className="col-12">
                      <FormGroup>
                        <Input
                          type="textarea"
                          name="message"
                          placeholder="Message"
                          value={this.state.message}
                          onChange={this.handleChange}
                        />
                      </FormGroup>
                    </div>
                    <div className="col-sm-4">
                      <FormGroup>
                        <Input
                          type="tel"
                          name="mobile"
                          placeholder="Mobile No."
                          value={this.state.mobile}
                          maxLength="10"
                          minLength="10"
                          pattern="^[0-9-+\s()]*$"
                          required
                          onChange={this.handleChange}
                        />
                      </FormGroup>
                    </div>
                    <div className="col-sm-8">
                      <FormGroup>
                        <Input
                          type="email"
                          name="email"
                          placeholder="Email"
                          value={this.state.email}
                          required
                          onChange={this.handleChange}
                        />
                      </FormGroup>
                    </div>
                    <div className="col-12">
                      <FormGroup>
                        <Input
                          type="text"
                          name="subject"
                          placeholder="Subject"
                          value={this.state.subject}
                          onChange={this.handleChange}
                        />
                      </FormGroup>
                    </div>
                    <div className="col-3">
                      <FormGroup>
                        <button
                          className="button button-contactForm boxed-btn"
                          type="submit"
                          name="send"
                        >
                          Send
                        </button>
                      </FormGroup>
                    </div>
                    <div className="col-12">
                      <Alert
                        isOpen={this.state.showSuccessAlert}
                        color="success"
                        closeLabel="Close Alert"
                        dismissible
                        toggle={() =>
                          this.setState({
                            showSuccessAlert: !this.state.showSuccessAlert,
                          })
                        }
                      >
                        Thanks for connecting!!
                      </Alert>
                      <Alert
                        isOpen={this.state.showErrorAlert}
                        color="danger"
                        closeLabel="Close Alert"
                        dismissible
                        toggle={() =>
                          this.setState({
                            showErrorAlert: !this.state.showErrorAlert,
                          })
                        }
                      >
                        Something went wrong!!
                      </Alert>
                    </div>
                  </div>
                </form>
              </div>

              <VisibilitySensor partialVisibility>
                {({ isVisible }) => (
                  <Spring
                    config={{ duration: 1000 }}
                    to={{
                      opacity: isVisible ? 1 : 0.2,
                    }}
                  >
                    {(props) => (
                      <div
                        style={{ props }}
                        className="col-lg-3 offset-lg-1 contact_card"
                      >
                        <div className="media">
                          <div className="black_logo" style={DivImgStyle}>
                            <img
                              style={ImgStyle}
                              src="assets/img/gallery/cover/BlackLogo.png"
                              alt="Logo White"
                            />
                          </div>
                        </div>
                        <div className="media media_tablet contact-info">
                          <span className="contact-info__icon">
                            <i className="flaticon-map"></i>
                          </span>
                          <div className="media-body">
                            <h3 style={H3Style}>Pune, Maharashtra</h3>
                          </div>
                        </div>
                        <div className="media media_tablet contact-info">
                          <span className="contact-info__icon">
                            <i className="flaticon-book"></i>
                          </span>
                          <div className="media-body">
                            <h3 style={H3Style}>+91 9545228983</h3>
                          </div>
                        </div>
                        <div className="media media_tablet contact-info">
                          <span className="contact-info__icon">
                            <i className="flaticon-email"></i>
                          </span>
                          <div className="media-body">
                            <h3 style={H3Style}>
                              <a
                                href="https://mail.google.com/mail/u/0/?view=cm&fs=1&tf=1&to=photuphactory@gmail.com"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                photuphactory@gmail.com
                              </a>
                            </h3>
                          </div>
                        </div>
                        <div className="media media_tablet contact-info">
                          <span className="contact-info__icon">
                            <i className="flaticon-instagram"></i>
                          </span>
                          <div className="media-body">
                            <h3 style={H3Style}>
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.instagram.com/omkarkalgude_photography/"
                              >
                                omkarkalgude_photography@instagram
                              </a>
                            </h3>
                          </div>
                        </div>
                        <div className="media media_tablet contact-info">
                          <span className="contact-info__icon">
                            <i className="flaticon-facebook"></i>
                          </span>
                          <div className="media-body">
                            <h3 style={H3Style}>
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.facebook.com/photu.phactory/"
                              >
                                photu.phactory@facebook
                              </a>
                            </h3>
                          </div>
                        </div>
                      </div>
                    )}
                  </Spring>
                )}
              </VisibilitySensor>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ContactUs;
