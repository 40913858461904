import React, { Component } from "react";
import PropTypes from "prop-types";
import LazyLoad from "react-lazy-load";
import VisibilitySensor from "react-visibility-sensor";
import { Spring } from "react-spring/renderprops";
const duration = 500;

export default class InstagramImages extends Component {
  render() {
    const { instagramImages } = this.props;

    return instagramImages.map((item, index) => {
      return (
        // Fade effect on partial visibility of images in browser
        <VisibilitySensor key={index} partialVisibility={true}>
          {({ isVisible }) => (
            <Spring
              config={{ duration: duration }}
              to={{
                opacity: isVisible ? 1 : 0.2,
              }}
            >
              {(props) => (
                <div
                  style={props}
                  key={item.id}
                  className="single_instagram col-xs-6 col-md-6 col-lg-12 col-xl-12 px-xl-0 px-lg-0"
                >
                  <a
                    href="https://www.instagram.com/omkarkalgude_photography/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {/* Lazy load the images when offset position at 500px vertically */}
                    <LazyLoad debounce={false} offsetVertical={500}>
                      <img src={item.image} alt="" />
                    </LazyLoad>
                  </a>
                  <div className="ovrelay">
                    <a
                      href="https://www.instagram.com/omkarkalgude_photography/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa fa-instagram" />
                    </a>
                  </div>
                </div>
              )}
            </Spring>
          )}
        </VisibilitySensor>
      );
    });
  }
}

InstagramImages.propTypes = {
  instagramImages: PropTypes.array.isRequired,
};
