import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Home from "./Components/Home";
import Header from "./Components/Shared/Header";
import Footer from "./Components/Shared/Footer";
import Album from "./Components/Albums/Album";
import ContactUs from "./Components/ContactUs/ContactUs";
import About from "./Components/About/About";
import Gallery from "./Components/Albums/Gallery";
import FilmContainer from "./Components/FilmContainer";
import "./Components/Shared/styles/404pageStyle.css";

// Page not found custom component for route /404
const NotFoundComponent = () => {
  return (
    <div className="not-found-container">
      <div className="not-found-content">
        <h2>404</h2>
        <h4>Oops! Page not found</h4>
        <p>
          The page you were looking for doesn&apos;t exist. You may have
          mistyped the address or the page may have moved
        </p>
        <a href="/">Back To Home</a>
      </div>
    </div>
  );
};

function App() {
  return (
    <div>
      <Router>
        <Header />
        {/* Routes defined for various sections of app */}
        <Switch>
          <Route exact path="/" component={Home}></Route>
          <Route path="/about" component={About} />
          <Route path="/contact" component={ContactUs} />
          <Route path="/films" component={FilmContainer}></Route>
          <Route path="/albums" component={Album} />
          <Route path="/gallery" component={Gallery} />
          <Route path="/404" component={NotFoundComponent} />
          <Redirect to="/404" />
        </Switch>

        <Footer />
      </Router>
    </div>
  );
}

export default App;
