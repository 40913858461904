import React from "react";
import { Spring } from "react-spring/renderprops";
import VisibilitySensor from "react-visibility-sensor";
import Category from "./Albums/Category";
import { categories } from "../AppData/category";
import HomeCarousel from "./Shared/HomeCarousel";
import FilmPlayer from "./Shared/FilmPlayer";
import InstagramImages from "./Shared/InstagramImages";

const TOTAL_IMAGES = 5;
const TOTAL_INSTA_IMAGES = 6;
const duration = 500;
const film = {
  id: 1,
  url: "https://youtu.be/zfy2U0ttszA",
  lightImg: "assets/img/banner/film1.jpg",
  lightImgLazy: "assets/img/banner/film_lazy.jpg",
};

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      categories: categories,
      carouselImages: [],
      instagramImages: [],
    };
  }

  // Load the images for carousel and instagram area in home page
  componentDidMount() {
    window.scrollTo(0, 0);
    let carouselImages = [];
    let instagramImages = [];
    carouselImages = [...Array(TOTAL_IMAGES)].map((_, index) => {
      return {
        id: index + 1,
        image: `assets/img/banner/banner${index + 1}.jpg`,
      };
    });
    instagramImages = [...Array(TOTAL_INSTA_IMAGES)].map((_, index) => {
      return {
        id: index + 1,
        image: `assets/img/instagram/${index + 1}.png`,
      };
    });
    this.setState({
      carouselImages: carouselImages,
      instagramImages: instagramImages,
    });
  }

  render() {
    return (
      <div>
        {this.state.carouselImages.length > 0 && (
          <HomeCarousel carouselImages={this.state.carouselImages} />
        )}

        {/* Fade effect when partially visible in browser */}
        <VisibilitySensor partialVisibility>
          {({ isVisible }) => (
            <Spring
              config={{ duration: duration }}
              to={{
                opacity: isVisible ? 1 : 0.2,
              }}
            >
              {(props) => (
                <div style={props} className="photography_skill_area">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-xl-9 col-md-9">
                        <div className="section_title text-center">
                          <h3>
                            Because every couple is special and so is their
                            story
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Spring>
          )}
        </VisibilitySensor>

        {/* gallery_area  */}
        <div
          style={{ paddingTop: "0px", paddingBottom: "0px" }}
          className="gallery_area"
        >
          <div className="container-fluid p-0">
            <div
              style={{ marginLeft: "7%", marginRight: "7%" }}
              className="row no-gutters "
            >
              {this.state.categories.slice(0, 4).map((category) => (
                <Category
                  key={category.id}
                  title={category.name}
                  displayName={category.displayName}
                  thumbnail={category.thumbnail}
                  imageCount={category.count}
                />
              ))}
            </div>
          </div>
        </div>
        {/* galleryArea */}

        <div style={{ margin: "20px 0px 35px 0px" }}>
          <div className="container">
            <h2 className="text_header">
              INDIAN CANDID WEDDING PHOTOGRAPHER & FILMMAKER
            </h2>
            <div
              style={{ textAlign: "center" }}
              className="row align-items-center text_content"
            >
              <p>
                Are you ready to experience a blend of magical emotions and
                relive them in the years to come? We’ve got our lenses ready.
                <br />
                We creatively capture your precious moments in a
                photojournalistic style with a fun, upbeat, and quirky approach.
                With years of happy tears behind our cameras, we document an
                elaborate experience that starts before your wedding day and
                extends beyond the 7 pheras. Your big day is full of cherishable
                memories and it deserves to be captured in its full essence.
              </p>
            </div>
          </div>
        </div>

        {/* React Player for youtube video playback */}
        <FilmPlayer data={film} />

        {/* Client Reviews section */}
        <VisibilitySensor partialVisibility>
          {({ isVisible }) => (
            <Spring
              config={{ duration: duration }}
              to={{
                opacity: isVisible ? 1 : 0.2,
              }}
            >
              {(props) => (
                <div
                  className="client"
                  style={{
                    ...props,
                    textAlign: "center",
                    // margin: "60px 0",
                    paddingTop: " 35px",
                    // paddingBottom: "70px",
                  }}
                >
                  <h2>OUR CLIENTS LOVE US</h2>
                  <div>
                    <div className="container client_review">
                      <div className="row align-items-center">
                        <div className="col-xl-6 col-md-6">
                          <img src="assets/img/line.png" alt="" width="100%" />
                          <p
                            className="mt2"
                            style={{ fontSize: "14px", textAlign: "justify" }}
                          >
                            I would like to say a huge thank you to team Photu
                            Phactory for such a superb service. From the moment
                            you arrived at the venue to the final stages of
                            producing our amazing album – you exceeded our
                            expectations. The photos are absolutely stunning and
                            the album itself has received so many compliments by
                            all who have viewed it. We truly cannot thank you
                            enough for this.
                          </p>
                          <h4>Aanchal & Kshitij</h4>
                        </div>
                        <div className="col-xl-6 col-md-6">
                          <img src="assets/img/line.png" alt="" width="100%" />
                          <p
                            className="mt2"
                            style={{ fontSize: "14px", textAlign: "justify" }}
                          >
                            Just a short note to say thank you so much for the
                            wonderful photos. The album is fabulous and we love
                            how all the effects and backgrounds we discussed
                            with you a few weeks ago have turned out. They have
                            really added to the presentation of the pictures. It
                            reminds us of our pre-wedding shoot days and your
                            approach helped contribute to the ‘relaxed’ time
                            that we enjoyed. We love it!
                          </p>
                          <h4>Vinika & Akhilesh</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Spring>
          )}
        </VisibilitySensor>
        {/* Client Reviews section */}

        <div className="instagram_text">
          <h3>Follow us on Instagram</h3>
          <div className="insta_link">
            <a
              href="https://www.instagram.com/omkarkalgude_photography/"
              target="_blank"
              rel="noopener noreferrer"
            >
              @omkarkalgude_photography
            </a>
          </div>
        </div>

        {/* Instagram images Section */}
        <div className="instragram_area">
          <InstagramImages instagramImages={this.state.instagramImages} />
        </div>
        {/* Instagram images Section */}
      </div>
    );
  }
}

export default Home;
