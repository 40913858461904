import React, { Component } from "react";
import PropTypes from "prop-types";
// import LazyLoad from "react-lazy-load";
import { Carousel } from "react-responsive-carousel";
import { Spring } from "react-spring/renderprops";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import HomeCarouselSkeleton from "./HomeCarouselSkeleton";

export default class HomeCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      currentSlide: 0,
      isLoading: true,
    };
  }

  // Callback fired on next image request in carousel
  next = () => {
    this.setState((state) => ({
      currentSlide: state.currentSlide + 1,
    }));
  };

  // Callback fired on previous image request in carousel
  prev = () => {
    this.setState((state) => ({
      currentSlide: state.currentSlide - 1,
    }));
  };

  // Callback fired after the slide is changed in carousel
  updateCurrentSlide = (index) => {
    const { currentSlide } = this.state;

    if (currentSlide !== index) {
      this.setState({
        currentSlide: index,
      });
    }
  };

  // Callback fired when first image in carousel loads and carousel skeleton disappears
  stopLoader = () => {
    this.setState({ isLoading: false });
  };

  render() {
    return (
      <Spring
        from={{ opacity: 0 }}
        to={{ opacity: 1 }}
        config={{ duration: 800 }}
        delay={800}
      >
        {(props) => (
          <div
            className="padding_top"
            style={{
              ...props,
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <div onClick={this.prev} className="my-prev-arrow">
              <i
                className="fa fa-angle-left"
                style={{ fontSize: "1.7em", color: "#8080807a" }}
              />
            </div>
            <div onClick={this.next} className="my-next-arrow">
              <i
                className="fa fa-angle-right"
                style={{ fontSize: "1.7em", color: "#8080807a" }}
              />
            </div>

            <Carousel
              key={this.state.isLoading}
              showIndicators={false}
              showStatus={false}
              showThumbs={false}
              infiniteLoop
              autoPlay={true}
              stopOnHover={false}
              interval={3000}
              dynamicHeight
              showArrows={false}
              selectedItem={this.state.currentSlide}
              onChange={this.updateCurrentSlide}
            >
              {this.props.carouselImages.map((item) => {
                return (
                  <div key={item.id}>
                    <img
                      style={{
                        display: this.state.isLoading ? "none" : "block",
                      }}
                      onLoad={() => {
                        if (item.id === 1) this.stopLoader();
                      }}
                      alt=""
                      src={item.image}
                    />
                  </div>
                );
              })}
            </Carousel>

            {this.state.isLoading && <HomeCarouselSkeleton />}
          </div>
        )}
      </Spring>
    );
  }
}

HomeCarousel.propTypes = {
  carouselImages: PropTypes.array.isRequired,
};
