import React, { Component } from "react";
import { Link } from "react-router-dom";
import LazyLoad from "react-lazy-load";
import { showTitle } from "../../Utils/Utility";
import PropTypes from "prop-types";
import { Spring } from "react-spring/renderprops";
import VisibilitySensor from "react-visibility-sensor";

export default class Category extends Component {
  // Begin polling for the required lazy images tag and observe the images for intersection
  componentDidMount() {
    const albumThumbImagePoll = setInterval(() => {
      if (document.querySelectorAll(".lazy_album_image").length > 0) {
        clearInterval(albumThumbImagePoll);
        const observer = new IntersectionObserver((entry, imgObserver) => {
          entry.forEach((element) => {
            if (element.isIntersecting) {
              const lazyImage = element.target;
              const originalImage = lazyImage.src.replace("1.jpg", "thumb.jpg");
              lazyImage.src = originalImage;
              lazyImage.classList.remove("lazy_album_image");
              imgObserver.unobserve(lazyImage);
            }
          });
        });

        const elementArray = document.querySelectorAll(".lazy_album_image");
        elementArray.forEach((element) => {
          observer.observe(element);
        });
      }
    }, 200);
  }

  render() {
    const { title, thumbnail, displayName, imageCount } = this.props;
    return (
      <div style={{ padding: "1%" }} className="col-xl-6 col-md-6">
        <div
          style={{
            height: "100%",
          }}
          className="single_photography"
        >
          <VisibilitySensor partialVisibility>
            {({ isVisible }) => (
              <Spring
                config={{ duration: 500 }}
                to={{
                  opacity: isVisible ? 1 : 0.2,
                }}
              >
                {(props) => (
                  <>
                    <Link
                      to={{
                        pathname: "/gallery",
                        state: {
                          currentCategory: title,
                          displayName: displayName,
                          imageCount: imageCount,
                        },
                      }}
                    >
                      <div style={props} className={`thumb`}>
                        <LazyLoad offsetVertical={10}>
                          <img
                            className="lazy_album_image"
                            style={{ height: "100%" }}
                            src={thumbnail}
                            alt=""
                          />
                        </LazyLoad>
                      </div>
                    </Link>
                    <Link
                      to={{
                        pathname: "/gallery",
                        state: {
                          currentCategory: title,
                          displayName: displayName,
                          imageCount: imageCount,
                        },
                      }}
                    >
                      <div
                        className="albums_text"
                        style={{
                          ...props,
                          backgroundColor: "white",
                          textAlign: "center",
                          padding: "0.4em 0px 30px 0px",
                        }}
                      >
                        {/* Function to extract the Display Name for title */}
                        <span>{`${showTitle(displayName)[0]} `}</span>
                        <h5>View More</h5>
                      </div>
                    </Link>
                  </>
                )}
              </Spring>
            )}
          </VisibilitySensor>
        </div>
      </div>
    );
  }
}

Category.propTypes = {
  title: PropTypes.string.isRequired,
  thumbnail: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  imageCount: PropTypes.number.isRequired,
};
