import React, { Component } from "react";
import FilmPlayer from "./Shared/FilmPlayer";

const film = [
  {
    id: 1,
    url: "https://youtu.be/zfy2U0ttszA",
    lightImg: "assets/img/banner/film1.jpg",
    lightImgLazy: "assets/img/banner/film1_lazy.jpg",
  },
  {
    id: 2,
    url: "https://youtu.be/yZ9fncxLn8Q",
    lightImg: "assets/img/banner/film.jpg",
    lightImgLazy: "assets/img/banner/film_lazy.jpg",
  },
];

export default class FilmContainer extends Component {
  render() {
    return (
      <>
        <div style={{ textAlign: "center" }} className="col-lg-12 film_text">
          <h2 className="text-center film_title ">FILMING YOUR FAIRY TALE </h2>

          <p>
            As Cinematographers, our focus lies on capturing your romance with
            beautiful candid moments. Just like our photography, our videography
            is driven by our philosophy to portray your story in its full glory.
            The true essence of a wedding lies in the joy, love and all the
            bittersweet emotions that hide in every single moment. We skillfully
            bring out those hidden emotions through a visual timeline with
            minimal posing or acting. You deserve a fairy tale so we produce
            videos that are balanced with the most heartfelt emotions subtly
            portrayed through great visuals. Tell us your story and we’ll turn
            it into a cinematic fairy tale.
          </p>

          <div className="text-center pt-4">
            <h2>
              {"We turn your dream wedding into a fairy tale.".toUpperCase()}
            </h2>
            <h2>{"Talk to us today!".toUpperCase()}</h2>
          </div>
        </div>

        {/* <h3 className="film_text">Featured Films</h3> */}
        <div className="film_body">
          {film &&
            film.map((item) => {
              return (
                <>
                  {console.log(item)}
                  {film && (
                    <div className="film_area" key={item.id}>
                      <FilmPlayer data={item} />
                    </div>
                  )}
                </>
              );
            })}
        </div>
      </>
    );
  }
}
