import React from "react";
import Skeleton from "react-loading-skeleton";

// Skeleton loader for gallery page
const GallerySkeleton = () => {
  return (
    <React.Fragment>
      <div style={{ padding: "1%" }}>
        <Skeleton height={450} />
      </div>
      <div style={{ padding: "1%", display: "flex", flexDirection: "column" }}>
        {[...Array(20)].map((_, index) => (
          <div style={{ display: "flex", flexDirection: "row" }} key={index}>
            <div
              style={{ width: "25%", flex: 1, flexBasis: "25%", padding: "2%" }}
            >
              <Skeleton height={300} />
            </div>
            <div
              style={{
                width: "25%",
                flex: 1,
                flexBasis: "25%",
                padding: "2% ",
              }}
            >
              <Skeleton height={300} />
            </div>
          </div>
        ))}
      </div>
    </React.Fragment>
  );
};

export default GallerySkeleton;
